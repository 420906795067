import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../store/Authentication/auth.models';
import { AppConfigData } from '../config/appConfigData';
import { LocalStorageService } from './localStorage.service';
import { Router } from '@angular/router';
import { ChangePwdModal } from '../../shared/modals/common';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.currentUserSubject = new BehaviorSubject<User | null>(
      this.retrieveStoredUser()
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  private retrieveStoredUser(): User | null {
    if (isPlatformBrowser(this.platformId)) {
      const storedUser = this.localStorageService.getItem('currentUser');
      return storedUser ? JSON.parse(storedUser) : null;
    }
    return null; // Return null if not in a browser environment
  }

  public get currentUserValue(): any | null {
    return this.currentUserSubject.value;
  }

  login(
    email: string,
    password: string,
    deviceId: string,
    loginThroughOtp: boolean
  ): Observable<any> {
    let requestPayload;
    if (loginThroughOtp) {
      requestPayload = {
        userInput: email,
        otp: password,
        deviceType: 'web',
        deviceId,
      };
    } else {
      requestPayload = {
        userInput: email,
        password,
        deviceType: 'web',
        deviceId,
      };
    }
    return this.http.post<any>(AppConfigData.loginUrl, requestPayload).pipe(
      map((user) => {
        if (user && isPlatformBrowser(this.platformId)) {
          this.storeUserInLocalStorage(user);
          this.currentUserSubject.next(user.data.token);
        }
        return user;
      })
    );
  }

  apiError(): Observable<any> {
    this.currentUserSubject.next(null);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.clear();
    }
    this.router.navigate(['/auth/login']);
    return of(null); // Return an Observable
  }

  getPwdRegExp(): Observable<any> {
    let url = `${AppConfigData.pwdRegExp}`
    return this.http.get(url).pipe(
      catchError((error) => {
        console.error('getTemplates failed:', error);
        return throwError(error);
      })
    );
  }

  logout(): Observable<any> {
    let deviceId = localStorage.getItem('deviceId')
    return this.http.post<any>(AppConfigData.logOutUrl, { deviceId: deviceId }).pipe(
      map((res) => {
        if (res) {
          this.currentUserSubject.next(null);
          if (isPlatformBrowser(this.platformId)) {
            this.localStorageService.clear();
          }
          this.router.navigate(['/auth/login']);
        }
        return res;
      }),
      catchError((error) => {
        console.error('Logout failed:', error);
        return throwError(error);
      })
    );
  }

  autoRefreshToken(requestPayload: any): Observable<any> {
    return this.http.post<any>(AppConfigData.refreshToken, requestPayload).pipe(
      map((user) => {
        if (user && isPlatformBrowser(this.platformId)) {
          this.currentUserSubject.next(user.data.token,);
        }
        return user;
      })
    );
  }

  sendOtp(email: string): Observable<any> {
    const url = `${AppConfigData.sendOtpUrl}?email=${email}`;
    return this.http.post<any>(url, {}).pipe(map((otpResponse) => otpResponse));
  }

  getNotifications(): Observable<any> {
    const url = `${AppConfigData.getNotificatonsUrl}`;
    return this.http
      .get<any>(url, {})
      .pipe(map((notificationsresp) => notificationsresp));
  }

  clearNotifications(): Observable<any> {
    const url = `${AppConfigData.clearNotificatonsUrl}`;
    return this.http
      .post<any>(url, {})
      .pipe(map((clearnotifications) => clearnotifications));
  }

  readAllNotifications(): Observable<any> {
    const url = `${AppConfigData.readAllNotificatonsUrl}`;
    return this.http
      .post<any>(url, {})
      .pipe(map((clearnotifications) => clearnotifications));
  }

  readNotifications(notificationId:string): Observable<any> {
    const url = `${AppConfigData.readNotificatonsUrl}?notificationId=${notificationId}`;
    return this.http
      .post<any>(url, {})
      .pipe(map((clearnotifications) => clearnotifications));
  }

  forgotPassword(email: string): Observable<any> {
    const url = `${AppConfigData.forgotPwdUrl}?email=${email}`;
    return this.http
      .post<any>(url, {})
      .pipe(map((forgotpwdResp) => forgotpwdResp));
  }

  resetPassword(requestPayload: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.resetPwdUrl, requestPayload)
      .pipe(map((user) => user));
  }

  newpassword(requestPayload: any): Observable<any> {
    return this.http
      .post<any>(AppConfigData.mewPasswordUrl, requestPayload)
      .pipe(map((user) => user));
  }

  updatePassword(requestPayload: ChangePwdModal): Observable<any> {
    return this.http
      .post<any>(AppConfigData.updatePwdUrl, requestPayload)
      .pipe(map((resp) => resp));
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>(AppConfigData.getProfileUrl).pipe(
      map((res) => res),
      catchError((error) => {
        console.error('getUserProfile failed:', error);
        return throwError(error);
      })
    );
  }

  updateUserProfile(user: any): Observable<any> {
    const formData = new FormData();
    formData.append('FirstName', user.firstName);
    formData.append('LastName', user.lastName);
    formData.append('secondaryEmail', user.secondaryEmail);
    formData.append('SecondaryPhoneNumberCountryCodeId', user.secondaryCountryCode??'');
    formData.append('secondaryPhoneNumber', user.secondaryPhoneNumber);
    formData.append('File', user.profileImg);

    return this.http.post<any>(AppConfigData.updateProfileUrl, formData).pipe(
      map((res) => res),
      catchError((error) => {
        console.error('updateUserProfile failed:', error);
        return throwError(error);
      })
    );
  }

  private storeUserInLocalStorage(user: User): void {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.setItem('currentUser', JSON.stringify(user));
    }
  }
}
