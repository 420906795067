import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthenticationState } from './authentication.reducer';

export const getLayoutState =
  createFeatureSelector<AuthenticationState>('auth');

export const getUser = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.user
);

export const getisLoggedIn = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.isLoggedIn
);

export const getError = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.error
);

export const getSendOtpResponse = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.sendOtpResponse
);

export const getSendOtpError = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.sendOtpError
);

export const userDetails = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.userDetails
);

export const getUserProfileFailure = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.getUserProfileFailureError
);

export const refreshTokenRes = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.refreshTokenRes
);

export const getUpdatedUserProfileResponse = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.getUpdatedUserProfileResponse
);

export const getLoading = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.loading
);
export const getUpdatedUserProfileResponseReset = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.getUpdatedUserProfileResponseReset
);
export const getNewNotificationResponse = createSelector(
  getLayoutState,
  (state: AuthenticationState) => state.getNewNotificationResponse
);

