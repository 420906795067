import { createAction, props } from '@ngrx/store';
import { User } from './auth.models';
import { getUser } from './authentication-selector';
import { UserProfile } from '../../shared/modals/common';

// Register action
export const Register = createAction(
  '[Authentication] Register',
  props<{ email: string; username: string; password: string }>()
);
export const RegisterSuccess = createAction(
  '[Authentication] Register Success',
  props<{ user: User }>()
);
export const RegisterFailure = createAction(
  '[Authentication] Register Failure',
  props<{ error: string }>()
);

// login action
export const login = createAction(
  '[Authentication] Login',
  props<{
    userInput: string;
    password: any;
    deviceId: string;
    loginThroughOtp: boolean;
  }>()
);
export const loginSuccess = createAction(
  '[Authentication] Login Success',
  props<{ user: any }>()
);
export const loginFailure = createAction(
  '[Authentication] Login Failure',
  props<{ error: any }>()
);

export const sendOtp = createAction(
  '[Authentication] SEND OTP',
  props<{ userInput: string }>()
);
export const sendOtpSuccess = createAction(
  '[Authentication] SENDOTP Success',
  props<{ sendOtpResponse: any }>()
);
export const sendOtpFailure = createAction(
  '[Authentication] SENDOTP Failure',
  props<{ sendOtpError: any }>()
);

// refreshToken
export const refreshToken = createAction(
  '[Authentication] refreshToken',
  props<{ requestPayload: any }>()
);
export const refreshTokenSuccess = createAction(
  '[Authentication] refreshToken Success',
  props<{ refreshTokenRes: any }>()
);
export const refreshTokenFailure = createAction(
  '[Authentication] refreshToken Failure',
  props<{ refreshTokenerror: any }>()
);

// logout action
export const logout = createAction('[Authentication] Logout');
export const logoutSuccess = createAction(
  '[Auth] Logout Success',
  props<{ response: any }>()
);
export const logoutFailure = createAction(
  '[Authentication] Logout Failure',
  props<{ error: any }>()
);

//user profile
export const getUserProfile = createAction('[User] UserProfile');
export const updateUserProfile = createAction(
  '[User] UpdateUserProfile',
  props<{ user: UserProfile }>()
);
export const getUserProfileSuccess = createAction(
  '[User] UserProfile Success',
  props<{ userDetails: any }>()
);

export const getUserProfileFailure = createAction(
  '[User] UserProfile Failure',
  props<{ getUserProfileFailureError: any }>()
);

export const getUpdatedUserProfileSuccess = createAction(
  '[User] UserProfile Success',
  props<{ getUpdatedUserProfileResponse: any }>()
);

export const getNotificationList = createAction(
  '[Authentication] getNewNotification',
);
export const getNewNotificationSuccess = createAction(
  '[Authentication] getNewNotification Success',
  props<{ getNewNotificationResponse: any }>()
);
export const getNewNotificationFailure = createAction(
  '[Authentication] getNewNotification Failure',
  props<{ getNewNotificationError: any }>()
);



export const resetProfileState = createAction('[User] UserProfile reset');

export const resetLogin = createAction('[User] login reset');
